<script>
import { Bar} from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    chartData: {
      required: false,
      type: Object
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  data() {
    return {
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              scaleFontSize: 40,
              ticks: {
                display: true
              }
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  }
};
</script>
