<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea"><h3>
        <b>Laporan Surat Masuk Grafik</b>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <form method="post" @submit.prevent="filterData">
              <div class="form-body">
                <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Periode Tahun</label>
                      <div class="col-md-7">
                        <input type="text" class="form-control" placeholder="Pencarian Tahun" v-model="tahun" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- <div class="row">
                  <div class="pull-left">
                    <div class="for-group row">
                      <label class="control-label text-right mt-2 col-md-5">Nama Pembuat</label>
                      <div class="col-md-7">
                        <input 
                          type="text"
                          placeholder="NIP / NIK"
                          class="form-control"
                          v-model="pembuat"
                        />
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="pull-left">
                    <div class="for-group row">
                      <label class="control-label text-right mt-2 col-md-5">Unit / OPD</label>
                      <div class="col-md-7">
                        <input type="text" placeholder="Unit / OPD" class="form-control" v-model="unit">
                      </div>
                    </div>
                  </div>
                </div> -->

              </div>
              <hr>
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button type="submit" class="btn btn-success" title="Search">Submit</button>&nbsp;
                        <button type="button" @click="resetInput()" class="btn btn-inverse" title="Reset">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row justify-content-md-center" v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
          <h3>
          <b>&nbsp;</b>
          <div class="pull-right"></div>
        </h3>
        <hr>
        <div class="row">

          <div class="col-md-12">
            <div class="card">
              <div class="row">
                <div>
                  <div class="card-body">
                    <h4 class="font-medium text-inverse">Grafik Surat Masuk</h4>
                    <ul class="list-inline">
                      <li class="p-l-0">
                        <h6 class="text-muted"></h6>
                      </li>
                    </ul>
                    <div class="total-revenue4">
                      <line-chart :chart-data="lineBulan"></line-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="col-md-12">
            <div class="card">
              <div class="row">
                <div style="width:1000px;">
                  <div class="card-body">
                    <h4 class="font-medium text-inverse">Grafik Unit Kerja Pengirim / Asal Surat</h4>
                    <ul class="list-inline">
                      <li class="p-l-0">
                        <h6 class="text-muted"></h6>
                      </li>
                    </ul>
                    <div class="total-revenue4">
                      <line-chart :chart-data="lineUnitKerja"></line-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
    </div>

    <!-- {{ this.$store.state.laporanDokumenInGrafik.items.filters }} -->

  </div>
</template>
<script>
import Table from "@/components/table/table";
import moment from 'moment';
import { RotateSquare5 } from "vue-loading-spinner";
import downloadexcel from "vue-json-excel";
import { LineChart, BarChart } from "@/components/chart";
import axios from 'axios';

var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfmake = require("html-to-pdfmake");

export default {
  components: {
    RotateSquare5,
    Table,
    downloadexcel,
    "line-chart": LineChart,
    "bar-chart": BarChart,
  },
  data() {
    return {
      start_date : moment().startOf('year').format('YYYY-MM-DD'),
      end_date : moment().endOf('year').format('YYYY-MM-DD'),
      pembuat: '',
      unit: '',
      tahun: '',
      tanggal : new Date().getDate()+'-'+(new Date().getMonth()+1)+'-'+new Date().getFullYear(),
      nomor : 1,
      detail_bulan: {
        namabulan: this.$store.state.laporanDokumenInGrafik.items.finalbulannamabulan,
        totalbulan: this.$store.state.laporanDokumenInGrafik.items.finalbulantotalbulan
      },
    };
  },
  computed: {
    items() {
      return this.$store.state.laporanDokumenInGrafik.items ? this.$store.state.laporanDokumenInGrafik.items.items : [];
    },
    prints() {
      return this.$store.state.laporanDokumenInGrafik.items ? this.$store.state.laporanDokumenInGrafik.items.prints : [];
    },
    filters() {
      return this.$store.state.laporanDokumenInGrafik.items ? this.$store.state.laporanDokumenInGrafik.items.filters: {};
    },
    state() {
      return this.$store.state.laporanDokumenInGrafik;
    },
    tanggal_diterima(){
      return formatDate(this.$store.state.laporanDokumenInGrafik.tanggal_diterima);
    },
    urutan(){
      return this.$store.state.laporanDokumenInGrafik.urutan;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.laporan_docin){
        return !this.$store.state.profile.permissions.laporan_docin.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.laporan_docin){
        return !this.$store.state.profile.permissions.laporan_docin.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.laporan_docin){
        return !this.$store.state.profile.permissions.laporan_docin.delete;
      }
      return false;
    },
    lineBulan(){
      // let vm = this;
      let lineBulan = {
        labels: this.$store.state.laporanDokumenInGrafik.items.finaldatabulannamabulan,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenInGrafik.items.finaldatabulantotalbulan,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineBulan;
    },
    lineUnitKerja(){
      // let vm = this;
      let lineUnitKerja = {
        labels: this.$store.state.laporanDokumenInGrafik.items.finaldataunitnamaunit,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenInGrafik.items.finaldataunittotalunit,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineUnitKerja;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      prints: [],
    };
    this.$store.commit("laporanDokumenInGrafik/STATE", state);
    this.get();
  },
  methods: {
    get(val) {
      this.$store.dispatch("laporanDokumenInGrafik/getLaporanDokumenInGrafik", val);
    },
    getNow: function() {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date +' '+ time;
      this.timestamp = dateTime;
    },
    resetInput() {
      this.start_date = "";
      this.end_date = "";
      this.pembuat = "";
      this.tahun = "";
      this.unit = "";
      this.get(val);
    },
    pagination(params) {
      this.filterData(null, params);
    },
    filterData: function (event, params) {
      var val = {
        start_date: this.start_date,
        end_date: this.end_date,
        pembuat: this.pembuat,
        tahun: this.tahun,
        unit: this.unit,
      }
      if(typeof(params) == "object" && params.constructor.name === "Object") {
        val = Object.assign(params, val);
      }
      this.get(val);
    },
    doDetail(val) {
      this.$store.dispatch("laporanDokumenInGrafik/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("laporanDokumenInGrafik/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("laporanDokumenInGrafik/submitDelete", val);
    },


  }
};
</script>
